import { useEffect, useReducer } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { listEvents } from "controllers/event/queries";
import reducer from "controllers/event/reducer/listEventsReducer";
import { subscribeEvent } from "controllers/event/mutations";
import { RegistrationData } from "components/Forms/types";
import { SubmitHandler } from "react-hook-form";
import getProfileInfo from "controllers/profilo/graphql/queries";
import updateUserMutation from "controllers/profilo/graphql/mutations";
import toast from "react-hot-toast";
import { RegistrantType } from "components/Forms/variables";
import captureException from "helpers/sentryHelper";
import { useAuth } from "context/Auth";

const useListEventsHook = (registrantType: string) => {
  const { loading, data, error } = useQuery(listEvents, {
    variables: {
      filterDateFuture: "future",
      filterDatePast: "past",
      onlyEvents: true,
    },
    onError: (errorQuery) =>
      captureException({
        key: "component",
        value: "listEventsHookUseQuery",
        error: errorQuery,
        extra: null,
      }),
  });

  const { updateUserAttributes, user } = useAuth();

  // Query per effettuare il refetch dei dati profilo
  const [refetchProfileData] = useLazyQuery(getProfileInfo, {
    fetchPolicy: "network-only",
  });

  const [subscribeEventMutation, { loading: loadingSubscribe }] =
    useMutation(subscribeEvent);

  // Mutation per aggiornare i dati del profilo dopo aver effettuato l'iscrizione
  const [executeMutation] = useMutation(updateUserMutation);

  const [state, dispatch] = useReducer(reducer, {
    past: {
      list: [],
      loading: true,
      error: false,
    },
    future: {
      list: [],
      loading: true,
      error: false,
    },
    registeredEvents: [],
  });

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: "FIRST_LOAD",
        data: {
          future: {
            list: data?.events || [],
            loading: false,
            error: error || !data,
          },
          past: {
            list: data?.oldEvents || [],
            loading: false,
            errore: error || !data,
          },
          registeredEvents: data?.registeredEvents
            .filter((subscription) => subscription.sk)
            .map((subscription) => {
              const splitted = subscription.sk.split("#");
              const idEvent = splitted[1];
              return idEvent;
            }),
        },
      });
    }
  }, [loading]);

  /**
   * Chiama la mutation per effettuare l'accredito alla manifestazione
   */
  const accreditedToManifestation: SubmitHandler<RegistrationData> = async (
    dataAccredito,
    idEvent
  ) => {
    const payload = {
      idEvent,
      input: {
        ...dataAccredito,
        // Verifico che il campo registrantType sia valido e quindi faccia parte della lista
        // dei registrantType, se è valido lo passo in input
        ...(registrantType in RegistrantType && { registrantType }),
      },
    };

    try {
      const result = await subscribeEventMutation({
        variables: payload,
      });

      if (result?.data?.signupEvent) {
        dispatch({
          type: "ACCREDITA_UTENTE",
          data: {
            idEvent,
          },
        });

        toast.success("Iscrizione avvenuta con successo", {
          id: `accredito-${idEvent}`,
        });

        // Se va a buon fine l'iscrizione allora aggiorno i dati profilo e effettuo un refetch di questi dati
        // così da prendere i dati aggiornati alla prossima apertura del form
        const { email, ...input } = dataAccredito;
        executeMutation({
          variables: {
            input,
          },
        })
          .then(() => {
            refetchProfileData();
            // Se l'utente ha modificato il nome o il cognome, aggiorno questi due dati su cognito
            if (
              (input.firstName &&
                input.firstName !== user?.attributes?.given_name) ||
              (input.lastName &&
                input.lastName !== user?.attributes?.family_name)
            ) {
              updateUserAttributes({
                given_name: input.firstName,
                family_name: input.lastName,
              });
            }
          })
          .catch((err) => {
            captureException({
              key: "component",
              value: "listeventshook",
              error: err,
              extra: {
                action: "update-user-on-accredito",
                input: JSON.stringify(input),
              },
            });
          });

        return true;
      }
      toast.error("Qualcosa è andato storto, riprova più tardi", {
        id: `accredito-${idEvent}`,
      });
      return false;
    } catch (err) {
      if (err?.message === "Error: the subscription already exists") {
        toast.error("Sei già iscritto a questa manifestazione", {
          id: `accredito-${idEvent}`,
        });
        return false;
      }
      console.error(err);
      captureException({
        key: "component",
        value: "listeventshook",
        error: err,
        extra: {
          action: "accredito",
          payload: JSON.stringify(payload),
        },
      });
      toast.error("Qualcosa è andato storto, riprova più tardi", {
        id: `accredito-${idEvent}`,
      });
      return false;
    }
  };

  return {
    state,
    loadingSubscribe,
    accreditedToManifestation,
  };
};

export default useListEventsHook;
