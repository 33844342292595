import { orderEventFunctions } from "helpers/genericHelper";

function listEventsReducer(state, action) {
  switch (action.type) {
    case "FIRST_LOAD": {
      const orderedEvents = orderEventFunctions(
        Object.values(action.data.future?.list),
        true
      );
      const orderedOldEvents = orderEventFunctions(
        Object.values(action.data.past?.list),
        true,
        true
      );

      return {
        ...state,
        future: {
          ...action.data.future,
          list: orderedEvents,
        },
        past: {
          ...action.data.past,
          list: orderedOldEvents,
        },
        registeredEvents: action.data.registeredEvents,
      };
    }
    case "ACCREDITA_UTENTE":
      return {
        ...state,
        registeredEvents: [...state.registeredEvents, action.data.idEvent],
      };
    default:
      throw new Error("Caso non definito");
  }
}

export default listEventsReducer;
