import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";

interface componentProps {
  isOpen: boolean;
  closeModal: Function;
  title: string;
  children: ReactNode;
}

function Modal({ isOpen, closeModal, title, children }: componentProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 top-[104px] md:top-[112px] lg:top-[127px] lg:left-[320px] z-10 overflow-y-auto"
        onClose={() => closeModal()}
      >
        <div className="px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="bg-white fixed inset-0 top-[104px] md:top-[112px] lg:top-[127px] md:left-auto w-full md:max-w-sm">
              <div
                style={{ height: "calc(100% - 56px)" }}
                className="overflow-y-auto"
              >
                <Dialog.Title as="h3" className="text-base font-bold p-8 pb-0">
                  {title}
                </Dialog.Title>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
