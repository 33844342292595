import Loader from "components/Loader";
import EventCard from "components/Event/EventCard";
import { Event, EventTab } from "components/Event/types";

export interface componentProps {
  events: {
    list: Array<Event>;
    loading: boolean;
    error: Object;
  };
  registeredEvents: Array<string>;
  openModal: Function;
  eventTab: EventTab;
}

export default function ListEvents({
  events,
  registeredEvents,
  openModal,
  eventTab,
}: componentProps) {
  const { list, loading, error } = events;
  if (error) {
    return <p>Si è verificato un errore</p>;
  }
  if (loading) {
    return <Loader />;
  }
  return list.length > 0 ? (
    <>
      {list.map((event) => (
        <EventCard
          key={event.id}
          event={event}
          isSubscribed={registeredEvents.includes(event.id)}
          openModal={openModal}
          eventTab={eventTab}
        />
      ))}
    </>
  ) : (
    <p>Non ci sono manifestazioni</p>
  );
}
