import { Fragment, useEffect, useState } from "react";
import { useAppContext } from "context/App";
import { Tab } from "@headlessui/react";
import ListEvents from "components/Event/ListEvents";
import listEventsHook from "controllers/event/hooks/listEventsHook";
import Tabs from "components/Tabs";
import Modal from "components/Modal";
import SubscriptionForm from "components/Event/Subscription/SubscriptionForm";
import { SubmitHandler } from "react-hook-form";
import { RegistrationData } from "components/Forms/types";
import AppToaster from "components/AppToaster";
import { useRouter } from "next/router";
import Loader from "components/Loader";

export default function ListatoManifestazioni() {
  const { setAppNavigation } = useAppContext();
  const {
    query: { registrantType },
  } = useRouter();

  const { state, loadingSubscribe, accreditedToManifestation } = listEventsHook(
    registrantType as string
  );

  const [modalData, setModalData] = useState({
    open: false,
    idEvent: null,
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const tabs = [];
    // Mostro i tab solamente se sono presenti eventi nella rispettiva categoria
    if (state?.future?.list?.length > 0) {
      tabs.push("Future");
    }
    if (state?.past?.list?.length > 0) {
      tabs.push("Concluse");
    }
    setCategories(tabs);
  }, [state]);

  const openModal = (idEvent) => {
    window.scrollTo(0, 0);
    setModalData({
      open: true,
      idEvent,
    });
  };

  const closeModal = () => {
    setModalData({
      open: false,
      idEvent: null,
    });
  };

  const accreditaUtente = async (data) => {
    await accreditedToManifestation(data, modalData.idEvent);
    setModalData({
      open: false,
      idEvent: null,
    });
  };

  useEffect(() => {
    setAppNavigation({
      sidebar: "manifestazioni",
      breadcrumb: [
        { title: "Manifestazioni", href: "/manifestazioni", as: "/" },
      ],
    });
  }, []);

  if (!categories.length) {
    return <Loader />;
  }

  return (
    <>
      <Tab.Group>
        <Tabs categories={categories} />
        <Tab.Panels className="mt-3">
          {state.future?.list?.length > 0 && (
            <Tab.Panel key={0}>
              <ListEvents
                events={state.future}
                registeredEvents={state.registeredEvents}
                openModal={openModal}
                eventTab="future"
              />
            </Tab.Panel>
          )}
          {state.past?.list?.length > 0 && (
            <Tab.Panel key={1}>
              <ListEvents
                events={state.past}
                registeredEvents={state.registeredEvents}
                openModal={openModal}
                eventTab="past"
              />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
      <Modal
        isOpen={modalData.open}
        closeModal={closeModal}
        title="Iscriviti alla manifestazione"
      >
        <div className="px-8 pt-0">
          <SubscriptionForm
            subscribeToEvent={
              accreditaUtente as SubmitHandler<RegistrationData>
            }
            loading={loadingSubscribe}
            isAccreditation
            cancelAction={closeModal}
          />
        </div>
      </Modal>
      <AppToaster />
    </>
  );
}
