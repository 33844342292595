import { Event, EventTab } from "components/Event/types";
import convertEventDate from "helpers/datehelper";
import Button from "components/Forms/Button";
import Link from "next/link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import imgLoader from "helpers/urlHelper";
import Image from "next/image";
import stringHtmlTags from "helpers/genericHelper";

export interface componentProps {
  event: Event;
  isSubscribed: boolean;
  openModal: Function;
  eventTab: EventTab;
}

function EventCard({
  event,
  isSubscribed,
  openModal,
  eventTab,
}: componentProps) {
  const eventDate = convertEventDate(event.startDate, event.endDate);
  const openEventModal = () => {
    openModal(event.id);
  };

  return (
    <div className="w-full border-t border-white-dark mb-6 md:mb-8 relative">
      <div className="flex-1 flex flex-col justify-between">
        <div>
          <div className="flex mb-4">
            {event.logo?.length > 0 && (
              <div className="border border-t-0 border-white-dark min-h-[100px] md:min-h-[150px] h-[100px] md:h-[150px] min-w-[100px] md:min-w-[150px] w-[100px] md:w-[150px] flex justify-center relative mr-4">
                <Image
                  objectFit="contain"
                  layout="fill"
                  loader={imgLoader}
                  src={event.logo}
                  alt={`Logo manifestazione ${event.title}`}
                />
              </div>
            )}
            <div className="pt-4">
              <h3 className="text-3xl md:text-2xl font-bold text-gray-darker mb-2 md:mb-0">
                {event.title}
              </h3>
              <h4 className="text-sm md:text-5xl font-bold text-gray-darker mb-2">
                {eventDate.day}
              </h4>
              {event.contentDescription && (
                <p className="text-base text-gray-darker hidden md:block">
                  {stringHtmlTags(event.contentDescription)}
                </p>
              )}
            </div>
          </div>
          {event.contentDescription && (
            <p className="text-sm mb-4 text-gray-darker md:hidden">
              {stringHtmlTags(event.contentDescription)}
            </p>
          )}
        </div>
        <Link href={`/${event.id}`} passHref>
          <a className="subtitle2 text-gray-darker z-[2] md:ml-auto">
            Vai al calendario degli eventi
            <OpenInNewIcon className="text-primary w-5 inline-block align-middle ml-1" />
          </a>
        </Link>
        <div className="w-full md:w-auto md:absolute md:top-4 md:right-0 z-[3] mt-4 md:mt-0">
          {isSubscribed && (
            <Button
              className="btn btn-gray w-full md:w-auto"
              label="Iscritto"
            />
          )}
          {!isSubscribed && eventTab === "future" ? (
            <Button
              className="w-full md:w-auto btn btn-primary z-[2]"
              label="Iscriviti"
              onClick={openEventModal}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Link href={`/${event.id}`}>
        <a className="absolute inset-0 h-full w-full" />
      </Link>
    </div>
  );
}

export default EventCard;
