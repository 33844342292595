import { Tab } from "@headlessui/react";
import clsx from "clsx";

export interface componentProps {
  categories: Array<string>;
}

export default function Tabs({ categories }: componentProps) {
  return (
    <Tab.List className="w-full tabs md:mb-8 mx-[-16px] md:mx-0">
      {categories.map((category) => (
        <Tab
          key={category}
          className={({ selected }) =>
            clsx(
              "tab tab-lifted font-semibold text-base text-gray-dark bg-white",
              selected ? "tab-active text-primary-dark" : ""
            )
          }
        >
          {category}
        </Tab>
      ))}
    </Tab.List>
  );
}
